// prefer default export if available
var preferDefault = function preferDefault(m) {
  return m && m.default || m;
};

exports.components = {
  "component---src-pages-index-js": function componentSrcPagesIndexJs() {
    return import("/Users/federico/Development/Blockhaus/blockhaus-web/src/pages/index.js"
    /* webpackChunkName: "component---src-pages-index-js" */
    );
  }
};